import { dateFilter, textFilter } from "react-bootstrap-table2-filter";
import RangeFilter from "../../../components/RangeFilter";

export const headers1 = [
  "User Action",
  "User Type",
  "Today",
  "Week to date (Wtd)",
  "Month to Date (mtd)",
  "Year to Date (ytd)",
  "Total",
];
export const rows1 = [
  ["Login", 20, 20, 20, 20, 20, 20],
  ["Signup", 20, 20, 20, 20, 20, 20],
];
export const headers2 = [
  {
    text: "#",
    dataField: "id",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 50,
      };
    },
  },
  {
    text: "Content Name",
    dataField: "title",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Type",
    dataField: "contentType",
    sort: true,
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Enrolled",
    dataField: "enrolled",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "In-Progress",
    dataField: "inProgress",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Completed",
    dataField: "completed",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Views",
    dataField: "views",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
];

export const headers3 = [
  {
    text: "#",
    dataField: "id",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 50,
      };
    },
  },

  {
    text: "User Name",
    dataField: "userName",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },

  {
    text: "User Id",
    dataField: "ownerId",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },

  {
    text: "Journal Name",
    dataField: "name",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },

  {
    text: "Journal Type",
    dataField: "journalType",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },

  {
    text: "Status",
    dataField: "active",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },
  {
    text: "App Type",
    dataField: "appType",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },
  {
    text: "First Login",
    dataField: "lastLogin",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Total Number of journal Entries",
    dataField: "entryCount",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Last entries",
    dataField: "lastEntry",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
];

export const headers4 = ({
  userFilter = "",
  setManualFilter,
  setUserFilter,
}) => [
  {
    text: "#",
    dataField: "id",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 50,
        paddingTop: 8,
        paddingBottom: 8,
      };
    },
  },
  {
    text: "User ID",
    dataField: "userId",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Date & Time",
    dataField: "dateTime",
    // filter: dateFilter(),
    filter: textFilter(),
    headerAlign: "center",
    customFilterParameters: {},
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "User type",
    dataField: "userType",
    sort: true,
    filter: textFilter({
      defaultValue: userFilter,
      onFilter: (a) => {
        if (userFilter !== a) {
          setManualFilter(a);
        } else setManualFilter("");
      },
    }),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Application",
    dataField: "application",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
];

export const headers5 = [
  {
    text: "#",
    dataField: "id",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 50,
      };
    },
  },
  {
    text: "Webinar Name",
    dataField: "webinarName",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Course Name",
    dataField: "courseName",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Date & Time",
    dataField: "scheduledAt",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Duration",
    dataField: "durationMinutes",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Total Slots",
    dataField: "numSlots",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
  {
    text: "Booked",
    dataField: "numBooked",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
];
export const headers6 = [
  {
    text: "Keyword ",
    dataField: "keyword",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 100,
      };
    },
    filter: textFilter(),
  },
  {
    text: "Category",
    dataField: "categoryTitle",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 100,
      };
    },
    filter: textFilter(),
  },
  {
    text: "Synonyms",
    dataField: "synonyms",
    sort: true,
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 400,
      };
    },
  },
  {
    text: "Messages",
    dataField: "messages",
    sort: true,
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 400,
      };
    },
  },
  // { text: "URL", dataField: "urls", sort: true,headerAlign: 'center',headerStyle: () => {
  //   return {position:'sticky',top:0,fontWeight:'bold',paddingTop:11,paddingBottom:13,backgroundColor: '#63244E',color:'white',borderColor:'black',borderWidth:2}
  // }},
];
export const headers7 = [
  // {
  //   text:"User Type",dataField:'userType',headerAlign:'center',headerStyle: () => {
  //     return {position:'sticky',top:0,fontWeight:'bold',paddingTop:11,paddingBottom:13,backgroundColor: 'gray',color:'black',borderColor:'black',borderWidth:2,width:40}
  //   },
  // },

  {
    text: "Parent Messages",
    dataField: "suggestion",
    headerAlign: "center",
    headerStyle: () => {
      return {
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "gray",
        color: "black",
        borderColor: "black",
        borderWidth: 2,
        width: 100,
      };
    },
  },

  {
    text: "Teacher Messages",
    dataField: "url",
    headerAlign: "center",
    headerStyle: () => {
      return {
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "gray",
        color: "black",
        borderColor: "black",
        borderWidth: 2,
        width: 50,
      };
    },
  },
];

export const headers8 = ({ onDateChange = () => {}, startDate } = {}) => [
  {
    text: "Serial No",
    dataField: "entryId",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
        width: 120,
      };
    },
  },

  {
    text: "Journal Entry",
    dataField: "entryText",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },
  {
    text: "Mood",
    dataField: "mood",
    sort: true,
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
    filter: textFilter(),
  },
  {
    text: "Creation Date",
    dataField: "createdDate",
    // sort: true,
    headerAlign: "center",
    filter: dateFilter({}),
    headerFormatter: () => getCustomFilter(onDateChange, startDate),

    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Lock Status",
    dataField: "lockedStatus",
    sort: true,
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },

  {
    text: "Suggestion",
    dataField: "suggestion",
    sort: true,
    csvFormatter: (e) => {
      return e.replaceAll("’", "'");
    },
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: () => {
      return {
        position: "sticky",
        top: 0,
        fontWeight: "bold",
        paddingTop: 9,
        paddingBottom: 10,
        backgroundColor: "#63244E",
        color: "white",
        borderColor: "black",
        borderWidth: 2,
      };
    },
  },
];

function getCustomFilter(filterHandler, startDate) {
  return <RangeFilter filterHandler={filterHandler} startDate={startDate} />;
}
